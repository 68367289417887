/* Change this file to get your personal Portfolio */

// To change portfolio colors globally go to the  _globalColor.scss file

import emoji from "react-easy-emoji";
import splashAnimation from "./assets/lottie/splashAnimation"; // Rename to your file name for custom animation

// Splash Screen

const splashScreen = {
  enabled: true, // set false to disable splash screen
  animation: splashAnimation,
  duration: 2000 // Set animation duration as per your animation
};

// Summary And Greeting Section

const illustration = {
  animated: true // Set to false to use static SVG
};

const greeting = {
  username: "DAOULAT",
  title: "Hi all, I'm Khalil",
  subTitle: emoji(
    "A passionate Software Engineer 🚀 having an experience of building Web solutions with Java / Spring Boot and some other cool libraries and frameworks."
  ),
  resumeLink:
    "https://drive.google.com/file/d/1JZ26W7sgCNBYM-kltumH1PpB-GtFB7lj/view?usp=sharing", // Set to empty to hide the button
  displayGreeting: true // Set false to hide this section, defaults to true
};

// Social Media Links

const socialMediaLinks = {
  github: "https://github.com/Vvoox",
  linkedin: "https://www.linkedin.com/in/daoulatkhalil/",
  gmail: "daoulat.khalil@gmail.com",
  stackoverflow: "https://stackoverflow.com/users/14038426/khalil-daoulat",
  // Instagram, Twitter and Kaggle are also supported in the links!
  // To customize icons and social links, tweak src/components/SocialMedia
  display: true // Set true to display this section, defaults to false
};

// Skills Section

const skillsSection = {
  title: "What I do",
  subTitle: "Versatile Full Stack Developer eager to delve into diverse technological stacks and explore the limitless possibilities of the digital realm.",
  skills: [
    emoji(
      "⚡ Ability to design and implement efficient algorithms to address various computational problems."
    ),
    emoji("⚡ Strong analytical and problem-solving skills to efficiently identify and solve complex issues in code."),
    emoji(
      "⚡ Knowledge of operating systems and how they interact with software applications."
    )
  ],

  /* Make Sure to include correct Font Awesome Classname to view your icon
https://fontawesome.com/icons?d=gallery */

  softwareSkills: [
    {
      skillName: "Java",
      fontAwesomeClassname: "fab fa-java"
    },
    {
      skillName: "JavaScript",
      fontAwesomeClassname: "fab fa-js"
    },
    {
      skillName: "reactjs",
      fontAwesomeClassname: "fab fa-react"
    },
    {
      skillName: "docker",
      fontAwesomeClassname: "fab fa-docker"
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Education Section

const educationInfo = {
  display: true, // Set false to hide this section, defaults to true
  schools: [
    {
      schoolName: "National School of Applied Sciences",
      logo: require("./assets/images/ensa.png"),
      subHeader: "Master of Science in Computer Science",
      duration: "September 2016 - April 2019",
      desc: "Participated and Achievement in MCPC (Moroccan Collegiate Programming Contest)",
      descBullets: [
        "Familiarity with the core principles of computer science, including algorithms, data structures, " +
        "and operating systems, along with expertise in software engineering encompassing programming, " +
        "databases, and project management.",
      ]
    },
  ]
};

// Your top 3 proficient stacks/tech experience

const techStack = {
  viewSkillBars: true, //Set it to true to show Proficiency Section
  experience: [
    {
      Stack: "Backend",
      progressPercentage: "90%"
    },
    {
      Stack: "Frontend/Design", //Insert stack or technology you have experience in
      progressPercentage: "80%" //Insert relative proficiency in percentage
    },
  ],
  displayCodersrank: false // Set true to display codersrank badges section need to changes your username in src/containers/skillProgress/skillProgress.js:17:62, defaults to false
};

// Work experience section

const workExperiences = {
  display: true, //Set it to true to show workExperiences Section
  experience: [
    {
      role: "Senior Software Engineer",
      company: "Societe Generale",
      companylogo: require("./assets/images/sgma.png"),
      date: "June 2023 – Present",
      desc: "Specialize in crafting tailored solutions for a financial institution. My role involves not just coding expertise but also a deep understanding of the banking industry. Collaborating with cross-functional teams, I contribute to innovative software applications that streamline operations," +
          " enhance customer experiences, and ensure regulatory compliance",
      descBullets: [
        "Design, develop, and maintain software solutions for financial institution's specific needs.",
        "Utilize expertise in software development and deep understanding of the banking industry.",
          "Work with cross-functional teams, including business analysts and project managers."
      ]
    },
    {
      role: "Software Engineer",
      company: "Adria Business and Technology",
      companylogo: require("./assets/images/adria.png"),
      date: "Jan 2019 – Mars 2023",
      desc: "Contributed to the development of a Trade Finance module designed to simplify business transactions for importers and exporters. " +
          "The module establishes a standardized approach to handle various cases in trade operations.",
      descBullets: [
        "Help building solution standard to optimized the way we exchange transaction between core banking and our application.",
        "Determines operational feasibility by evaluating analysis, problem definition, requirements, solution development, and proposed solutions.",
        "Develops information systems by designing, developing, and installing software solutions."
      ]
    }
  ]
};

/* Your Open Source Section to View Your Github Pinned Projects
To know how to get github key look at readme.md */

const openSource = {
  showGithubProfile: "true", // Set true or false to show Contact profile using Github, defaults to true
  display: true // Set false to hide this section, defaults to true
};

// Some big projects you have worked on

const bigProjects = {
  title: "Big Projects",
  subtitle: "SOME STARTUPS",
  projects: [
    {
      image: require("./assets/images/3olba.png"),
      projectName: "3OLBA",
      projectDesc: "Empowering Opportunities, Transforming Futures - 3olba.com, where careers and aspirations converge.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://3olba.com/"
        }
        //  you can add extra buttons here.
      ]
    },
    {
      image: require("./assets/images/ad4cab.png"),
      projectName: "Ad4Cab",
      projectDesc: "Driving Success, One Advertisement at a Time.",
      footerLink: [
        {
          name: "Visit Website",
          url: "https://ad4cab.com/"
        }
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Achievement Section
// Include certificates, talks etc

const achievementSection = {
  title: emoji("Achievements And Certifications 🏆And Conference "),
  subtitle:
    "Achievements, Certifications, Award Letters and Some Cool Stuff that I have done !",

  achievementsCards: [
    {
      title: "Certificate of Achievement MCPC",
      subtitle:
        "Moroccan Collegiate Programming Contest",
      image: require("./assets/images/icpc.jpeg"),
      imageAlt: "Google Code-In Logo",
      footerLink: [
        {name: "Certification", url: ""},
      ]
    },
    {
      title: "Participation Certificat",
      subtitle:
        "Conference about Web security , share knowledge and risks to future engineers to give them the chance to know the new features in this domain.",
      image: require("./assets/images/uir.png"),
      imageAlt: "Google Assistant Action Logo",
      footerLink: [
        {name: "Certification", url: ""},
      ]
    },

    {
      title: "Certificate of Gratitude",
      subtitle: "Build a CTF event in ENSA FES university , for their students , " +
          "the aim is share our knowledge in programming and security and bring new technology by challenges each others.",
      image: require("./assets/images/ensaf.jpeg"),
      imageAlt: "PWA Logo",
      footerLink: [
        {name: "Certification", url: ""},
      ]
    }
  ],
  display: true // Set false to hide this section, defaults to true
};

// Blogs Section

const blogSection = {
  title: "Books",
  subtitle:
    "With Love for people cool stuff, I love to write and teach others what I have learnt.",
  displayMediumBlogs: "true", // Set true to display fetched medium blogs instead of hardcoded ones
  blogs: [
    {
      url: "https://www.researchgate.net/publication/349297753_Social_engineering_Arabic_Darija_Version",
      title: "Explore how cybercriminals and ethical hackers alike can leverage the intricacies of Moroccan dialects",
      description:
        "this book provides insights into the unconventional world of linguistic-based social engineering. You will discover : Strategies for tailoring phishing attacks and social engineering tactics. The role of language in building rapport and trust in the digital realm." +
          " An exploration of the psychology behind social engineering techniques, adapted for Moroccan culture"
    },
  ],
  display: true // Set false to hide this section, defaults to true
};

// Talks Sections

const talkSection = {
  title: "TALKS",
  subtitle: emoji(
    "I LOVE TO SHARE MY LIMITED KNOWLEDGE AND GET A SPEAKER BADGE 😅"
  ),

  talks: [
    {
      title: "Build Actions For Google Assistant",
      subtitle: "Codelab at GDG DevFest Karachi 2019",
      slides_url: "https://bit.ly/saadpasta-slides",
      event_url: "https://www.facebook.com/events/2339906106275053/"
    }
  ],
  display: false // Set false to hide this section, defaults to true
};

// Podcast Section

const podcastSection = {
  title: emoji("Podcast 🎙️"),
  subtitle: "I LOVE TO TALK ABOUT MYSELF AND TECHNOLOGY",

  // Please Provide with Your Podcast embeded Link
  podcast: [
    "https://anchor.fm/codevcast/embed/episodes/DevStory---Saad-Pasta-from-Karachi--Pakistan-e9givv/a-a15itvo"
  ],
  display: false // Set false to hide this section, defaults to true
};

const contactInfo = {
  title: emoji("Contact Me ☎️"),
  subtitle:
    "Discuss a project or just want to say hi? My Inbox is open for all.",
  // email_address: "daoulat.khalil@gmail.com"
};

// Twitter Section

const twitterDetails = {
  userName: "twitter", //Replace "twitter" with your twitter username without @
  display: false // Set true to display this section, defaults to false
};

const isHireable = false; // Set false if you are not looking for a job. Also isHireable will be display as Open for opportunities: Yes/No in the GitHub footer

export {
  illustration,
  greeting,
  socialMediaLinks,
  splashScreen,
  skillsSection,
  educationInfo,
  techStack,
  workExperiences,
  openSource,
  bigProjects,
  achievementSection,
  blogSection,
  talkSection,
  podcastSection,
  contactInfo,
  twitterDetails,
  isHireable
};
